(function () {
    'use strict';

    angular
        .module('alpha.utils.Modals', [
            'ui.bootstrap',
            'alpha.common.services.authentication'
        ])
        .factory('ModalService', ModalService)
        .constant('MODAL_PATHS',{
            ALERT: '/Alpha/static/custom/common/app/partials/modals/alert.html',
            CONFIRMATION: '/Alpha/static/custom/common/app/partials/modals/confirmation.html',
            CONFIRM_DIRTY_STATE: '/Alpha/static/custom/common/app/partials/modals/confirmDirtyState.html',
            LAYOUT_FORM: '/Alpha/static/custom/layoutDesigner/partial/modals/layoutForm.html',
            LAYOUT_INSTRUCTIONS: '/Alpha/static/custom/layoutDesigner/partial/modals/layoutInstructions.html',
            BUSINESS_OBJECT_INSTRUCTIONS: '/Alpha/static/custom/businessObjectWorkbench/partial/modals/businessObjectInstructions.html',
            DELETE_CONFIRMATION: '/Alpha/static/custom/common/app/partials/modals/deleteConfirmation.html',
            INVALID_FILE_TYPES_WARNING: '/Alpha/static/custom/viewRecord/partial/directives/invalidFileTypesWarning.html'
        });

    ModalService.$inject = ['MODAL_PATHS', 'AuthenticationService'];

    function ModalService(MODAL_PATHS, AuthenticationService) {
        return {
            openAlert: openAlert,
            openConfirmation: openConfirmation,
            openDirtyStateConfirmation: openDirtyStateConfirmation,
            openLayoutForm: openLayoutForm,
            openCopyLayoutForm: openCopyLayoutForm,
            openLayoutInstructions: openLayoutInstructions,
            openBusinessObjectInstructions: openBusinessObjectInstructions,
            deleteConfirmation:  deleteConfirmation,
            openStaticAlert: openStaticAlert,
            openInvalidFileTypesWarningModal: openInvalidFileTypesWarningModal
        };

        /**
         * Opens a modal with a message.
         *
         * @method openAlert
         *
         * @param {String} [title] Optional title to override the default
         * @param {String} msg Message to display in the modal body
         *
         * @returns {Object} A promise to be resolved when confirmed or closed
         */
        function openAlert(title, msg) {
            var options = {
                animation: false,
                windowClass: 'modal-default',
                templateUrl: MODAL_PATHS.ALERT,
                controller: ['$scope', '$uibModalInstance', 'I18nUtil','$sce', controller]
            };
            function controller($scope, $uibModalInstance, I18nUtil,$sce) {
                $scope.title = title || I18nUtil.getI18nString('LBL_ALERT', 'Alert');
                $scope.msg = $sce.trustAsHtml(msg);
                $scope.ok = function() {
                    $uibModalInstance.close();
                };
            }
            return AuthenticationService.openAuthenticatedModal(options);
        }
        /**
         * Opens a modal with a message with backdrop static.
         *
         * @method openAlert
         *
         * @param {String} [title] Optional title to override the default
         * @param {String} msg Message to display in the modal body
         *
         * @returns {Object} A promise to be resolved when confirmed or closed
         */
        function openStaticAlert(title, msg) {
            var options = {
                backdrop:'static',
                animation: false,
                windowClass: 'modal-default modal-noclose',
                templateUrl: MODAL_PATHS.ALERT,
                controller: ['$scope', '$uibModalInstance', 'I18nUtil', controller]
            };
            function controller($scope, $uibModalInstance, I18nUtil) {
                $scope.title = title || I18nUtil.getI18nString('LBL_ALERT', 'Alert');
                $scope.msg = msg;
                $scope.ok = function() {
                    $uibModalInstance.close();
                };
            }
            return AuthenticationService.openAuthenticatedModal(options);
        }
        /**
         * Opens a modal with a message and the option to continue or cancel.
         *
         * @method openConfirmation
         *
         * @param {String} msg Message to display in the modal body
         * @param {String} [title] Optional title to override the default
         *
         * @returns {Object} A promise to be resolved when confirmed or rejected when dismissed or closed
         */
        function openConfirmation(msg, title) {
            var options = {
                animation: false,
                windowClass: 'modal-default',
                templateUrl: MODAL_PATHS.CONFIRMATION,
                controller: ['$scope', '$uibModalInstance', 'I18nUtil', controller]
            };
            function controller($scope, $uibModalInstance, I18nUtil) {
                $scope.title = title || I18nUtil.getI18nString('LBL_PLEASE_CONFIRM', 'Please Confirm');
                $scope.msg = msg;
                $scope.yes = function() {
                    $uibModalInstance.close();
                };
                $scope.no = function() {
                    $uibModalInstance.dismiss();
                };
            }
            return AuthenticationService.openAuthenticatedModal(options);
        }
        /**
         * Opens a modal with a message and the option to cancel or delete.
         *
         * @method deleteConfirmation
         *
         * @param {String} msg Message to display in the modal body
         * @param {String} [title] Optional title to override the default
         *
         * @returns {Object} A promise to be resolved when confirmed or rejected when dismissed or closed
         */
        function deleteConfirmation(msg, title) {
            var options = {
                animation: false,
                windowClass: 'modal-default',
                templateUrl: MODAL_PATHS.DELETE_CONFIRMATION,
                controller: ['$scope', '$uibModalInstance', 'I18nUtil', controller]
            };
            function controller($scope, $uibModalInstance, I18nUtil) {
                $scope.title = title || I18nUtil.getI18nString('LBL_DELETE', 'Delete');
                $scope.msg = msg;
                $scope.delete = function() {
                    $uibModalInstance.close();
                };
                $scope.cancel = function() {
                    $uibModalInstance.dismiss();
                };
            }
            return AuthenticationService.openAuthenticatedModal(options);
        }
        /**
         * Opens a modal with a message and the option to save, continue, or cancel.
         *
         * @method openDirtyStateConfirmation
         *
         * @param {String} [title] Optional title to override the default
         * @param {String} [msg] Optional message to override the default
         * @param {Boolean} hideSaveBtn Whether to hide the save button; defaults to false
         *
         * @returns {Object} A promise to be resolved when confirmed or rejected when dismissed or closed
         */
        function openDirtyStateConfirmation(title, msg, hideSaveBtn,hideContinueBtn) {
            var options = {
                animation: false,
                windowClass: 'modal-default modal-dirty-state',
                templateUrl: MODAL_PATHS.CONFIRM_DIRTY_STATE,
                controller: ['$scope', '$uibModalInstance', 'I18nUtil', controller]
            };
            function controller($scope, $uibModalInstance, I18nUtil) {
                $scope.title = title || I18nUtil.getI18nString('LBL_UNSAVED_CHANGES', 'Unsaved Changes');
                $scope.msg = msg || I18nUtil.getI18nString('MSG_YOU_HAVE_UNSAVED_CHANGES', 'You have unsaved changes. Do you want to continue?');
                $scope.hideSaveBtn = hideSaveBtn;
                $scope.hideContinueBtn = hideContinueBtn;

                $scope.cancel = function() {
                    $uibModalInstance.dismiss();
                };
                $scope.save = function() {
                    $uibModalInstance.close({action: 'save'});
                };
                $scope.continue = function() {
                    $uibModalInstance.close({action: 'continue'});
                };
            }
            return AuthenticationService.openAuthenticatedModal(options);
        }

        // TODO: These module-specific methods should be removed from this service, they do not belong here

        /**
         * Open modal to create a layout
         * @param {Object} layoutData - Layout data to bind to the form
         * @returns {promise}
         */
        function openLayoutForm(layoutData, pageNameValidator){
            var options = {
                animation: false,
                windowClass: 'modal-default modal-layout-form',
                templateUrl: MODAL_PATHS.LAYOUT_FORM,
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.cancel = function () {
                        $uibModalInstance.dismiss();
                    };
                    $scope.ok = function () {
                        $uibModalInstance.close($scope.data);
                    };
                    $scope.title = 'Page Layout Details';
                    $scope.data = layoutData;
                    $scope.pageNameValidators = [{
                        name: 'uniqueLayoutName',
                        callback: pageNameValidator
                    }];
                }]
            };
            return AuthenticationService.openAuthenticatedModal(options);
        }

        /**
         * Open modal to create a copy of a layout
         * @param {Object} layoutData - Layout data to bind to the form
         * @returns {promise}
         */
        function openCopyLayoutForm(layoutData, pageNameValidator){
            var options = {
                animation: false,
                windowClass: 'modal-default modal-layout-form',
                templateUrl: MODAL_PATHS.LAYOUT_FORM,
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.cancel = function () {
                        $uibModalInstance.dismiss();
                    };
                    $scope.ok = function () {
                        $uibModalInstance.close($scope.data);
                    };
                    $scope.title = 'Copy Page Layout Details';
                    $scope.data = layoutData;
                    $scope.copiedLayout = true;
                    $scope.pageNameValidators = [{
                        name: 'uniqueLayoutName',
                        callback: pageNameValidator
                    }];
                }]
            };
            return AuthenticationService.openAuthenticatedModal(options);
        }

        /**
         * Open modal containing Layout Designer instructions
         */
        function openLayoutInstructions(){
            var options = {
                animation: false,
                windowClass: 'modal-instruction',
                templateUrl: MODAL_PATHS.LAYOUT_INSTRUCTIONS,
                controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                    $scope.ok = function(){
                        $uibModalInstance.dismiss();
                    };
                }]
            };
            AuthenticationService.openAuthenticatedModal(options);
        }

        /**
         * Open modal containing Business Object Designer instructions
         */
        function openBusinessObjectInstructions(){
            var options = {
                animation: false,
                windowClass: 'modal-instruction',
                templateUrl: MODAL_PATHS.BUSINESS_OBJECT_INSTRUCTIONS,
                controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                    $scope.ok = function(){
                        $uibModalInstance.dismiss();
                    };
                }]
            };
            AuthenticationService.openAuthenticatedModal(options);
        }

        function openInvalidFileTypesWarningModal(title, invalidTypes, overSizeAttachments, clientMaxAttachmentSize) {
            var options = {
                animation: false,
                windowClass: 'modal-default',
                templateUrl: MODAL_PATHS.INVALID_FILE_TYPES_WARNING,
                controller: ['$scope', '$uibModalInstance', 'I18nUtil', controller]
            };
            function controller($scope, $uibModalInstance, I18nUtil) {
                $scope.title = title || I18nUtil.getI18nString('LBL_WARNING', 'Warning');
                $scope.invalidTypes = invalidTypes;
                $scope.overSizeAttachments = overSizeAttachments;
                $scope.clientMaxAttachmentSize = I18nUtil.getI18nString('LBL_OVERSIZE_EMAIL_ATTACHMENTS', 'The following email attachments exceeds configured maximum of {{clientMaxAttachmentSize}} bytes', { clientMaxAttachmentSize: clientMaxAttachmentSize });

                $scope.ok = function() {
                    $uibModalInstance.close();
                };
            }
            return AuthenticationService.openAuthenticatedModal(options);
        }
    }
})();
